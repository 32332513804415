import type { inferProcedureInput, inferProcedureOutput } from '@trpc/server'
import { type Accessor } from 'solid-js'

import type { AppRouter } from '#/trpc/server'
import { createTrpcQuery } from '#/trpc/solidTrpc'
import { createEndlessScroll } from '#/utils/createEndlessScroll'

export type EndlessSongResource = ReturnType<typeof createEndlessSongResource>

type Input = inferProcedureInput<AppRouter['getSongs']>
type Output = inferProcedureOutput<AppRouter['getSongs']>['items'][number]

export const createEndlessSongResource = (input?: Accessor<Input>) => {
  const page = createEndlessScroll<Output, Input>(
    () => input?.() ?? {},
    (input) => {
      const [songs] = createTrpcQuery({
        query: 'getSongs',
        skip: () => input().searchTerm === '',
        input,
      })

      return songs
    },
  )

  return page
}
