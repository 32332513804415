import type { Accessor, Resource } from 'solid-js'
import {
  batch,
  createComputed,
  createEffect,
  createMemo,
  createSignal,
  onCleanup,
} from 'solid-js'
import { createStore } from 'solid-js/store'
import { isServer } from 'solid-js/web'

import { createMappedState } from './module/createMappedState'
import { createListWithMutations } from './signalWithMutations'

export type Page<T> = {
  items: T[]
  nextPageCursor?: string
}

export type EndlessScrollResource<
  T,
  I extends { cursor?: string },
> = ReturnType<typeof createEndlessScroll<T, I>>

export function createEndlessScroll<T, I extends { cursor?: string }>(
  input: Accessor<Omit<I, 'cursor'>>,
  createResource: (input: Accessor<I>) => Resource<Page<T> | undefined>,
) {
  const [store, setStore] = createStore({
    items: [] as T[],
    nextCursor: undefined as string | undefined,
    hasReachedEnd: false,
  })

  const [isIntersecting, setIsIntersecting] = createSignal(false)

  const io = isServer
    ? undefined
    : new IntersectionObserver((entries) => {
        setIsIntersecting(!!entries?.find((e) => e.isIntersecting))
      })

  createEffect(() => {
    if (store.hasReachedEnd || contents.loading) return

    if (isIntersecting()) {
      setStore({
        nextCursor: contents.latest?.nextPageCursor,
      })
    }
  })

  onCleanup(() => io?.disconnect())

  const addEndOfListRef = (el: Element) => {
    io?.observe(el)
    onCleanup(() => io?.unobserve(el))
  }

  const inputWithCursor = createMemo(() => ({
    ...input(),
    cursor: store.nextCursor,
  }))

  createEffect(() => {
    // reset state when input changes
    input()

    setStore({
      items: [],
      nextCursor: undefined,
      hasReachedEnd: false,
    })
  })

  const contents = createResource(() => inputWithCursor() as I)

  const isLoading = createMemo(() => contents.loading)

  createComputed(() => {
    const content = contents.latest
    if (!content) return

    const { items, nextPageCursor } = content

    batch(() => {
      if (!nextPageCursor) {
        setStore({ hasReachedEnd: true })
      }

      setStore((prev) => ({ items: [...prev.items, ...items] }))
    })
  })

  const [mutatedItems, { mutateItem, mutateList, rollbackMutation }] =
    createListWithMutations(() => store.items)

  return createMappedState(store, (state) => {
    const mappedState = createMemo(() => ({
      ...state,
      items: mutatedItems(),
      addEndOfListRef,
      mutateItem,
      mutateList,
      rollbackMutation,
      isLoading: isLoading(),
    }))

    return mappedState
  })
}
