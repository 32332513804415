import { Index, Show } from 'solid-js'

import { createTrpcMutation } from '#/trpc/solidTrpc'

import type { FlexProps } from '../ui/Flex'
import { Flex } from '../ui/Flex'
import { Spinner } from '../ui/Spinner'
import type { EndlessSongResource } from './createEndlessSongResource'
import { SongCard } from './SongCard'
import styles from './SongList.module.css'

type SongListProps = FlexProps & {
  endlessSongResource: EndlessSongResource
  small?: boolean
}

export function SongList(props: SongListProps) {
  const [{ mutate: updateSongLike }] = createTrpcMutation({
    mutation: 'updateSongLike',
  })

  const onClickLike = async (song: { _id: string; isLikedByUser: boolean }) => {
    const isLikedByUser = !song.isLikedByUser

    const mutationIndex = props.endlessSongResource.mutateItem(
      ({ _id }) => _id.toString() === song._id,
      (song) => ({ ...song, isLikedByUser }),
    )

    try {
      await updateSongLike({ songId: song._id, isLikedByUser })
    } catch {
      props.endlessSongResource.rollbackMutation(mutationIndex)
    }
  }

  return (
    <Flex
      {...props}
      direction="column"
    >
      <div
        classList={{ [styles.SongListInner!]: true }}
        data-small={props.small || null}
      >
        <Index each={props.endlessSongResource.items}>
          {(item) => (
            <SongCard
              song={item()}
              onClickLike={() =>
                onClickLike({
                  _id: item()._id.toString(),
                  isLikedByUser: item().isLikedByUser,
                })
              }
            />
          )}
        </Index>
      </div>
      <Show when={!props.endlessSongResource.hasReachedEnd}>
        <div class={styles.LoadingIndicator}>
          <div
            class={styles.Trigger}
            ref={props.endlessSongResource.addEndOfListRef}
          >
            <Show when={props.endlessSongResource.isLoading}>
              <Spinner />
            </Show>
          </div>
        </div>
      </Show>
    </Flex>
  )
}
