import type { inferRouterOutputs } from '@trpc/server'
import { VsEdit, VsHeart, VsHeartFilled } from 'solid-icons/vs'
import { createMemo, Show } from 'solid-js'
import { navigate } from 'vike/client/router'

import { AdminGuard } from '#/auth/ui/AdminGuard'
import { t } from '#/i18n/I18nProvider'
import {
  useSubscriptionActions,
  useSubscriptionState,
} from '#/subscription/state/SubscriptionContext'
import type { AppRouter } from '#/trpc/server'
import { entries } from '#/utils/objectFromEntries'

import { Link } from '../Link'
import { Button } from '../ui/Button'
import { Flex } from '../ui/Flex'
import styles from './SongCard.module.css'

export const SongCard = (props: {
  song: inferRouterOutputs<AppRouter>['getSongs']['items'][number]
  onClickLike?: () => void
}) => {
  const { requireSubscription } = useSubscriptionActions()
  const subscription = useSubscriptionState()

  const handleClick = async () => {
    if (!subscription.hasActiveSubscription) {
      await requireSubscription()

      navigate(href())
    }
  }

  const href = createMemo(() => `/songs/${props.song._id}`)

  return (
    <Flex
      direction="column"
      classList={{
        [styles.SongCard!]: true,
      }}
    >
      <Link
        onClick={handleClick}
        href={subscription.hasActiveSubscription ? href() : ''}
      >
        <div class={styles.Image}>
          <img
            src={props.song.covers?.[400] ?? ''}
            srcSet={entries(props.song.covers ?? {})
              .map(([key, value]) => `${value} ${key}w`)
              .join(', ')}
            sizes={entries(props.song.covers ?? {})
              .map(([key]) => `(max-width: ${key}px) ${key}px`)
              .join(', ')}
            alt={props.song.workTitle ?? props.song.songName}
          />
          <div class={styles.SongCardTitle}>
            {props.song.workTitle ?? props.song.songName}
          </div>
        </div>
        <Show when={props.song.level}>
          {(level) => (
            <>
              <div
                class={styles.LevelCorner}
                data-level={props.song.level}
              >
                <div
                  class={styles.Level}
                  data-level={props.song.level}
                >
                  {t(`song.level.${level()}`)}
                </div>
              </div>
            </>
          )}
        </Show>
      </Link>
      <Show when={!props.song.isPublished}>
        <div class={styles.Preview}>{t('song.preview')}</div>
      </Show>
      <Flex class={styles.SongCardInfo}>
        <AdminGuard>
          <Button
            as={Link}
            variant={'ghost'}
            class={styles.Heart}
            href={`/admin/songs/${props.song._id}`}
          >
            <VsEdit />
          </Button>
        </AdminGuard>
        <Button
          onClick={(event: Event) => {
            event.stopPropagation()
            props.onClickLike?.()
          }}
          variant={'ghost'}
          data-filled={props.song.isLikedByUser}
          class={styles.Heart}
        >
          <Show
            when={props.song.isLikedByUser}
            fallback={<VsHeart />}
          >
            <VsHeartFilled />
          </Show>
        </Button>
      </Flex>
    </Flex>
  )
}
